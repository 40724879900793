.atw_video_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;

    .atw_video_registration {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
        border-radius: 10px;
        background-color: #FFFFFFB2;

        .separator {
            width: 1px;
            margin: 0 40px;
            background-color: black;
        }

        .registration_cta {
            text-decoration: underline;
            font-weight: 600;
            color: #528d73;
            cursor: pointer;
        }
    }
}