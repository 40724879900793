.atw_main_container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    background-color: #f4e4c3;

    .atw_bkg_topRight {
        position: fixed;
        top: 0;
        right: 0;
        height: 200px;
    }

    .atw_bkg_topLeft {
        position: fixed;
        top: 0;
        left: 0;
        width: 400px;
    }

    .atw_bkg_bottomRight {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 600px;
    }

    .atw_main_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1 0 auto;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .atw_logo_nexidigital {
        position: fixed;
        height: 40px;
        bottom: 30px;
        right: 50%;
        transform: translateX(50%);
        opacity: .7;
    }
}