.atw_registration_container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: white;
    padding: 60px;
    border-radius: 10px;

    .atw_logo {
        height: 150px;
    }

    .qrCode {
        height: 200px;
    }

    a {
        text-decoration: underline;
        font-weight: 600;
        color: #528d73;
        cursor: pointer;
    }
}