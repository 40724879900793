.atw_password_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #FFFFFFB2;
    padding: 80px;
    border-radius: 10px;

    .atw_password_content {
        margin-top: 60px;

        form {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 10px;
        }

        .atw_password_copy {
            font-size: 18px;
            font-weight: 600;
        }

        input {
            padding: 15px;
            border-radius: 10px;
            outline: none;
            border: 2px solid #528d73;
        }

        button {
            background-color: #528d73;
            border: none;
            color: white;
            font-weight: bold;
            border-radius: 10px;
            padding: 15px;
        }

        .message {
            font-size: 14px;
            font-weight: 600;
            color: red;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin: 5px 0;
        }
    }
}